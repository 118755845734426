import { auth } from '@telekomconsalting/dex-guru-internal-sdk'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { addLastNotification, addNotification } from '../actions/notificationsAction'
import { useComponentDidMount } from '../hooks/useComponentDidMount'
import { LastNotificationMessage, NotificationMessage } from '../model'
import ws from '../services/WebsocketService'
import { ConnectionType, SubscribeItemType } from '../services/WebsocketService/type'
import { WebSocketClient } from '../services/WebsocketService/websocketService'
import WebsocketServiceContext from '../services/WebsocketService/wsContext'
import { useAuth } from './AuthWrapper'

const WebsocketsWrapper: FC = (props) => {
  const [wsClient, setWsClient] = useState<WebSocketClient | null>(null)
  const [wsClientState, setWsClientState] = useState<ConnectionType>(ConnectionType.CLOSED)
  const reduxDispatch = useDispatch()
  const { accountId } = useAuth()

  useEffect(() => {
    if (!accountId || wsClientState !== ConnectionType.READY) {
      return
    }

    const accessToken = auth.getAccessToken()
    if (!accessToken) {
      return
    }

    const { account_id } = JSON.parse(atob(accessToken.split('.')[1])) as { account_id: number }

    const feedChannel = `Notifications.Feed.${account_id}`
    const lastChannel = `Notifications.Last.${account_id}`

    const feedHandler = {
      uuid: feedChannel,
      reconnect: false,
      onMessage: (data: unknown): void => {
        const value = data as NotificationMessage
        reduxDispatch(addNotification(value.update))
      },
      params: {},
    }

    ws.subscribe(feedChannel, SubscribeItemType.notify, {}, feedHandler)

    const lastHandler = {
      uuid: lastChannel,
      reconnect: false,
      onMessage: (data: unknown): void => {
        const value = data as LastNotificationMessage
        reduxDispatch(addLastNotification(value.update))
      },
      params: {},
    }

    ws.subscribe(lastChannel, SubscribeItemType.notify, {}, lastHandler)
  }, [accountId, reduxDispatch, wsClientState])

  useComponentDidMount(() => {
    ws.init(setWsClientState).then(() => {
      setWsClient(ws)
    })
  })

  return (
    <WebsocketServiceContext.Provider value={{ ws: wsClient, wsState: wsClientState }}>
      {props.children}
    </WebsocketServiceContext.Provider>
  )
}

export default WebsocketsWrapper
