import { useWeb3React } from '@telekomconsalting/core'
import { isSuppressEagerConnect } from '@telekomconsalting/react-dexguru-wallet'
import React, { FC, useContext, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { setWalletAddress } from '../actions'
import { initTokens, updateCurrentToken } from '../actions/tokenActions'
import { setTraderProfileTxn, setTraderProfileWallet } from '../actions/traderProfileActions'
import { useConnectors } from '../components/ConnectorsContext'
import { appGitVersion, CURRENT_TOKEN_REFRESH_INTERVAL } from '../config/settings'
import { FREEZE_SNACKBAR_KEY, VERSION_SNACKBAR_KEY } from '../constants'
import { usePrevious } from '../hooks'
import useLazyEagerConnect from '../hooks/useLazyEagerConnect'
import useTokenPrice from '../hooks/useTokenPrice'
import { WsChannelNames } from '../hooks/useTokenPrice/reSubscribeTokenPrice'
import { OgDynamicMetadata } from '../model'
import { State } from '../reducers'
import {
  buildTokenRoute,
  HISTORY_ROUTE_TEMPLATE,
  LIQUIDITY_ROUTE_TEMPLATE,
  MULTICHART_ROUTE,
  PRICING_ROUTE,
  ROOT_ROUTE,
  SEASON_PASS_ROUTE,
  TOP_CRYPTOS_ROUTE,
} from '../routes'
import amplitudeService from '../services/amplitudeService'
import { updateMetadata } from '../services/metadataService'
import { isWithinTheLastWeek } from '../services/sidebarsService'
import { getTokenDetails } from '../services/tokenService'
import { newVersionAvailableCheck } from '../services/versionService'
import Loader from './../components/Loader'
import { SnackBarWrapperContext } from './SnackbarWrapper'

const reloadTimer: NodeJS.Timeout | null = null

const Web3Wrapper: FC = ({ children }) => {
  const { removeSnackbar, showSnackbar, openedSnackbar } = useContext(SnackBarWrapperContext)
  const isFreezeTVChartRoute = useRouteMatch([LIQUIDITY_ROUTE_TEMPLATE, HISTORY_ROUTE_TEMPLATE])
  const isMultichart = Boolean(useRouteMatch(MULTICHART_ROUTE))
  const isSeasonPass = Boolean(useRouteMatch(SEASON_PASS_ROUTE))
  const isPricing = Boolean(useRouteMatch(PRICING_ROUTE))
  useEffect(() => {
    window.freezeTVChart = Boolean(isFreezeTVChartRoute)
  }, [isFreezeTVChartRoute])

  const history = useHistory()
  const {
    token,
    tradeId,
    walletAddress,
  }: {
    token: string
    walletAddress: string
    tradeId: string
  } = useParams()

  const { walletConnectors, initWalletConnectors } = useConnectors()

  const [ogMetadata, setOgMetadata] = useState<OgDynamicMetadata | undefined>()

  const { account, library, connector, active } = useWeb3React()

  const { currentToken, tokenLoading, quoteToken } = useSelector((state: State) => state.tokens)
  const currency = useSelector((state: State) => state.currency)
  const { isSidebarOpen } = useSelector((state: State) => state.ui)

  const { currentTradingHistory, currentTradingHistoryOrigin } = useSelector(
    (state: State) => state.tradingHistory
  )

  const traderProfileState = useSelector((state: State) => state.traderProfile)

  const { data: networksConfig, isLoaded: networksConfigLoaded } = useSelector(
    (state: State) => state.networksConfig
  )

  const prevCurrentToken = usePrevious(currentToken)
  useTokenPrice({ currency, token: currentToken, channel: WsChannelNames.priceCurrent })
  useTokenPrice({ currency, token: quoteToken, channel: WsChannelNames.priceQuote })

  const reduxDispatch = useDispatch()

  if (walletAddress && traderProfileState.walletAddress !== walletAddress) {
    reduxDispatch(setTraderProfileWallet({ walletAddress: walletAddress }))
    reduxDispatch(setTraderProfileTxn({ activeTxn: tradeId }))
  }

  if (!walletAddress && traderProfileState.walletAddress !== undefined) {
    reduxDispatch(setTraderProfileWallet({ walletAddress: undefined }))
    reduxDispatch(setTraderProfileTxn({ activeTxn: undefined }))
  }

  /* START HOOKS */
  useEffect(() => {
    const setWallet = async (): Promise<void> => {
      const providerName = await walletConnectors?.getProviderName(connector)

      if (active && account && providerName) {
        reduxDispatch(setWalletAddress(account, providerName))
        amplitudeService.walletConnected({ wallet_address: account, wallet_provider: providerName })
      }
    }

    setWallet()
  }, [active, account, connector, networksConfig, reduxDispatch, walletConnectors])

  // load last tokens list
  useEffect(() => {
    const doVersionCheck = async (): Promise<void> => {
      const newVersionAvailable = await newVersionAvailableCheck(appGitVersion)

      if (newVersionAvailable && !openedSnackbar?.includes(VERSION_SNACKBAR_KEY)) {
        showSnackbar && showSnackbar(VERSION_SNACKBAR_KEY)
      }
    }

    if (networksConfigLoaded && currentToken?.id) {
      if (!openedSnackbar?.includes(VERSION_SNACKBAR_KEY)) {
        doVersionCheck()
      }
    }
  }, [networksConfigLoaded, networksConfig, currentToken?.id, openedSnackbar, showSnackbar])

  useEffect(() => {
    if (networksConfigLoaded && currentToken?.id) {
      updateMetadata(currentToken, setOgMetadata).then()
    }
  }, [currentToken, networksConfigLoaded])

  useEffect(() => {
    if (!currentToken?.id) {
      reduxDispatch(initTokens(token, account, library))
    }
  }, [account, currentToken?.id, library, reduxDispatch, token])

  useEffect(() => {
    const currentTokenPollingInterval = setInterval(async () => {
      if (!currentToken?.id) {
        return
      }
      const currentTokenDetails = await getTokenDetails(currentToken.id)
      if (!currentTokenDetails) {
        return
      }
      currentTokenDetails && reduxDispatch(updateCurrentToken(currentTokenDetails))
    }, CURRENT_TOKEN_REFRESH_INTERVAL)
    return (): void => {
      clearInterval(currentTokenPollingInterval)
    }
  }, [currentToken?.id, currentToken?.network, quoteToken?.id, quoteToken?.network, reduxDispatch])

  useEffect(() => {
    const staleMarketCheck = (): void => {
      if (!currentTradingHistory) {
        return
      }

      const isTradingHistoryEmpty = currentTradingHistory.length === 0

      const hasNoTransactionsLastWeek =
        currentTradingHistory.length && !isWithinTheLastWeek(currentTradingHistory[0])

      // NOTE: do not use symbols, it is more likely to have a false positive since the swap can include the next token too (or previous)
      const hasTradingHistoryCurrentToken =
        currentTradingHistory.length &&
        currentToken?.address &&
        currentTradingHistory[0].tokenAddresses.includes(currentToken?.address)

      if (
        !openedSnackbar?.includes(FREEZE_SNACKBAR_KEY) &&
        currentToken?.id &&
        isSidebarOpen !== 'history' &&
        currentTradingHistoryOrigin === 'all' &&
        currentToken?.id &&
        (isTradingHistoryEmpty || (hasTradingHistoryCurrentToken && hasNoTransactionsLastWeek))
      ) {
        showSnackbar && showSnackbar(FREEZE_SNACKBAR_KEY)
      } else if (
        openedSnackbar?.includes(FREEZE_SNACKBAR_KEY) &&
        currentToken?.id &&
        currentToken?.id !== prevCurrentToken?.id
      ) {
        removeSnackbar && removeSnackbar(FREEZE_SNACKBAR_KEY)
      }
    }

    staleMarketCheck()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentToken?.id,
    // currentToken?.address,
    currentTradingHistory,
    // currentTradingHistoryOrigin,
    // isSidebarOpen,
    // openedSnackbar,
    // prevCurrentToken?.id,
    // removeSnackbar,
    // showSnackbar,
  ])

  useEffect(() => {
    const path = history.location.pathname

    if (path.includes(TOP_CRYPTOS_ROUTE)) {
      history.push(path)
      return
    }

    if (path.includes(SEASON_PASS_ROUTE)) {
      return
    }

    if (isPricing) {
      return
    }

    if (!walletAddress && currentToken?.id) {
      updateMetadata(currentToken, setOgMetadata).then()
    }

    if (
      // only allow to go from wallet to token if it's not at startup
      (!walletAddress || (prevCurrentToken?.id && prevCurrentToken?.id !== currentToken?.id)) &&
      currentToken?.id &&
      token !== currentToken.id
    ) {
      if (!isMultichart && !isSeasonPass) {
        if (!token) {
          // keep params, if they must be cleared, they will... elsewhere
          history.push(buildTokenRoute(currentToken.id))
          return
        } else {
          history.push({
            pathname: path.replace(token, currentToken.id),
            search: history.location.search,
          })
          return
        }
      }
    }
    // don't find any token
    if (
      currentToken === null &&
      !token &&
      !walletAddress &&
      !isMultichart &&
      !isSeasonPass &&
      !isPricing
    ) {
      history.push(ROOT_ROUTE)
    }
  }, [
    currentToken?.id,
    currentToken?.priceUSD,
    account,
    isMultichart,
    isSeasonPass,
    isPricing,
    history,
    walletAddress,
    currentToken,
    prevCurrentToken?.id,
    token,
  ])

  useLazyEagerConnect(
    isSuppressEagerConnect(),
    walletConnectors,
    initWalletConnectors,
    networksConfig
  )
  /* END HOOKS */

  useEffect(() => {
    if (networksConfig?.length && currentToken?.id) {
      reloadTimer && clearTimeout(reloadTimer)
    }
  }, [networksConfig?.length, currentToken?.id])

  // eslint-disable-next-line no-console
  console.log('*** web3 wrapper', account, connector)

  if (!currentToken?.id || tokenLoading) {
    return <Loader isDashboard={false} />
  }

  return (
    <>
      {ogMetadata && (
        <Helmet>
          <meta name="description" content={ogMetadata.description} />
          <meta property="og:title" content={ogMetadata.title} />
          <meta property="og:description" content={ogMetadata.description} />
          <meta name="twitter:title" content={ogMetadata.title} />
          <meta name="twitter:description" content={ogMetadata.description} />
        </Helmet>
      )}
      {!ogMetadata && (
        <Helmet>
          <meta property="og:title" content="DexGuru - DeFi Trading Terminal" />
          <meta
            property="og:description"
            content="Guru️ knows a thing or two about what is going on at AMM DEXs"
          />
          <meta name="twitter:title" content="DexGuru - DeFi Trading Terminal" />
          <meta
            name="twitter:description"
            content="Guru️ knows a thing or two about what is going on at AMM DEXs"
          />
        </Helmet>
      )}
      {children}
    </>
  )
}

export default Web3Wrapper
